import React from 'react'
import LandingPageSearch from '../../templates/landing-page-search'

import content from '../../data/pages/article/opf-get-funded-fast-3/content.json'

const OPFGetFundedFastLP = () => (
  <LandingPageSearch content={content} />
)

export default OPFGetFundedFastLP
