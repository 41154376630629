import React from 'react'
import LandingPageAdvertorial from '../../templates/landing-page-advertorial'

import content from "../../data/test/templates/landing-page-advertorial/content.json"

const TestTemplateLandingPageAdvertorial = () => (
  <LandingPageAdvertorial content={content} />
)

export default TestTemplateLandingPageAdvertorial
