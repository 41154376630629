module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.ico"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T382V8","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-57655706-1"],"gtagConfig":{"linker":{"domains":["oneparkfinancial.com","app.oneparkfinancial.com","lp.oneparkfinancial.com"]}},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-convert-com-js/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"10034997-10033055","requireProduction":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
