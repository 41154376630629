import React, { Component } from "react"
import $ from 'jquery';
import Title from '../atoms/title'
import Button from '../atoms/button'
import QuestionnaireStep from '../molecules/questionnaire-step'

class QuestionnaireTeaser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.content.title,
            subTitle: this.props.content.subTitle,
            step: this.props.content.step,
            questionnairePath: this.props.content.questionnairePath,
            showButton: this.props.content.showButton,
            buttonID: this.props.content.step.body[0].form.buttonID,
            formFieldSelection: '',
            sliderValue: 0
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    
    handleChange(e, newSliderValue = false) {
        if (!this.state.showButton) {
            let URLparameters = e.target.name + '=' + e.target.value;
            let URLparametersEncoded = window.btoa(URLparameters);
            let href = (this.props.UTMparams !== '') ? this.state.questionnairePath + this.props.UTMparams + '&resp=' + URLparametersEncoded : this.state.questionnairePath + '?resp=' + URLparametersEncoded;
            window.location.href = href;
        } else if (newSliderValue) {
            this.setState({
				sliderValue: newSliderValue,
			});
        }
    }
    
    handleClick() {
        let stepSlider = $('.cm-questionnaire-teaser .ca-slider-field');
        let URLparameters = stepSlider.data('slider') + '=' + this.state.sliderValue;
        let URLparametersEncoded = window.btoa(URLparameters);
        let href = (this.props.UTMparams !== '') ? this.state.questionnairePath + this.props.UTMparams + '&resp=' + URLparametersEncoded : this.state.questionnairePath + '?resp=' + URLparametersEncoded;
        window.location.href = href;
    }
    
    render() {
        const createClassList = () => {
            let classList = 'cm-questionnaire-teaser';
            if(this.props.type) classList = classList + ' cm-questionnaire-teaser--' + this.props.type;
            if(this.props.classNames) classList = classList + ' ' + this.props.classNames;

            return classList;
        }

        const titleComponent = (this.state.title) ? (
            <Title
                type='h2'
                title={this.state.title}
                align='center'
                color='light'
                classNames='cm-questionnaire-teaser__title'
            />
        ) : null;

        const subTitleComponent = (this.state.subTitle) ? (
            <Title
                type='h4'
                title={this.state.subTitle}
                align='center'
                color='light'
                classNames='cm-questionnaire-teaser__sub-title'
            />
        ) : null;

        const buttonComponent = (this.state.showButton) ? (
            <div className='cm-questionnaire-teaser__button'>
                <Button
                    type='primary'
                    label='Next'
                    id={this.state.buttonID}
                    onClick={this.handleClick}
                    large
                    isForm
                />
            </div>
        ) : null;

        // const buttonURL = (this.state.formFieldSelection === '') ? '/questionnaire' : '/questionnaire?' + this.props.formFieldName + '=' + this.state.formFieldSelection;

        const questionnaireStepComponent = (
            <QuestionnaireStep
                title={this.state.step.title}
                subTitle={this.state.step.subTitle}
                body={this.state.step.body}
                onChange={this.handleChange}
                classNames='cm-questionnaire-teaser__step'
                sliderValue={this.state.sliderValue}
            />
        );
        
        return (
            <div className={createClassList()}>
                <div className='cm-questionnaire-teaser__wrapper'>
                    <div className='cm-questionnaire-teaser__heading'>
                        {titleComponent}
                        {subTitleComponent}
                    </div>
                    {questionnaireStepComponent}
                    {buttonComponent}
                </div>
            </div>
        );
    }
}

export default QuestionnaireTeaser;