import React from 'react';
import { Slider } from '@material-ui/core';
import Title from '../atoms/title';

const SliderField = ({
	className = false,
	classNameJS = false,
	isRequired = false,
	errorMsg = null,
	sliderName = '',
	sliderValue = 0,
	defaultValue = 0,
	min = 0,
	max = 1000,
	step = 1,
	buttonID = null,
	onChange = null,
	saveResponse = true,
	validateAPI = false,
	sliderIcon = ''
}) => {
	const createClassList = () => {
		let classList = 'ca-slider-field';
		if (className) classList = classList + ' ca-slider-field--' + className;
		if (classNameJS) classList = classList + ' ' + classNameJS;
		if (isRequired) classList = classList + ' js-required';
		if (sliderIcon !== '') classList = classList + ' ca-slider-field--' + sliderIcon;

		return classList;
	};

	const numberWithCommas = (number) => {
		return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
	};

	return (
		<div 
			className={createClassList()} 
			data-slider={sliderName} 
			data-button-id={buttonID}
		>
			<Title 
				type='h2' 
				title={'$' + numberWithCommas(sliderValue)} 
				align='center' 
				color='dark' 
				weight='light' 
			/>
			<Slider 
				defaultValue={defaultValue} 
				min={min} 
				max={max} 
				onChange={onChange} 
				name={sliderName} 
				step={step} 
				data-save={saveResponse}
				data-validate-api={validateAPI}
				className='ca-slider-field__slider'
			/>
			<span className='ca-slider-field__error-msg'>{errorMsg}</span>
		</div>
	);
};

export default SliderField;

{
	/* <SliderField
    classNameJS="js-show--button"
    isRequired={item.form.required}
    buttonID={item.form.buttonID}
    sliderName={item.form.name}
    defaultValue={item.sliderOptions.defaultValue}
    min={item.sliderOptions.min}
    max={item.sliderOptions.max}
    step={item.sliderOptions.step}
    onChange={this.handleChange}
/> */
}
