import React from 'react'
import SEO from "../../../../components/atoms/seo"


import Layout from '../../../../components/organisms/layout'
import Heading from '../../../../components/molecules/heading'
import Questionnaire from '../../../../components/organisms/questionnaire'
import KeyFeatures from '../../../../components/molecules/key-features'

import steps from "../../../../data/pages/product/form/22/tcw15/steps.json"
import content from "../../../../data/pages/product/form/22/tcw15/content.json"

const QuestionnairePage = () => (
  <Layout>
    <SEO title={content.seo.title} />
    <Heading 
        title={content.heading.title}
        subTitle={content.heading.subTitle}
        weight='light'
    />
    <Questionnaire 
      steps={steps}
      content={content.questionnaire}
      thankYouURL={content.questionnaire.onSubmit.thankYouURL}
      thankYouChatBot
      sliderIcon='truck'
      showTextLoader
      formName='tcw15-22'
    />
    <KeyFeatures
      features={content.keyFeatures}
    />
  </Layout>
)

export default QuestionnairePage
