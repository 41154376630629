import React, { Component } from "react"
import $ from 'jquery';
import {
	grabResponseParam,
	isObjEmpty,
	replaceAllHelper,
} from '../../helpers';
import Title from '../atoms/title'
import Loader from '../molecules/loader'
import QuestionnaireStep from './questionnaire-step'

class ChatBot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.content.title,
            subTitle: this.props.content.subTitle,
            steps: this.props.content.steps,
            questionnairePath: this.props.content.questionnairePath,
            formFieldSelection: '',
            isTyping: false,
			totalSteps: this.props.content.steps.length,
            prevState: 0,
			currentStep: 1,
			nextStep: 2,
			lastStep: false,
            response: {},
            startChat: false,
            endChat: false,
            showSteps: false,
            userResponse: '',
            userInterracted: false,
            rules: []
        }

        this.variables = {
            number: '%%NUMBER%%',
            email: '%%EMAIL%%',
            name: '%%NAME%%',
            business: '%%BUSINESS%%'
        }

        this.readUrlParams = this.readUrlParams.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getRules = this.getRules.bind(this);
        this.handleLoaderComplete = this.handleLoaderComplete.bind(this);
    }

    componentDidMount() {
		this.readUrlParams();
        this.startChat();
	}

    componentDidUpdate() {
        this.scrollToBottom();
    }

    readUrlParams() {
		let responseParamObj = grabResponseParam('chat');

		if(!isObjEmpty(responseParamObj)) {
			Object.keys(responseParamObj).forEach((key, index) => {
                let value = responseParamObj[key];
				this.saveResponse(key, value);
			})
		}
	}

    saveResponse = (key, val) => {
		this.setState((prevState) => ({
			response: {
				...prevState.response,
				[key]: val,
			},
		}));
        this.scrollToBottom();
	};

    startChat() {
        setTimeout(() => {
            this.setState({
                startChat: !this.state.startChat
            });
        }, 500);
        setTimeout(() => {
            this.setState({
                isTyping: !this.state.isTyping
            });
            setTimeout(() => {
                this.setState({
                    showSteps: !this.state.showSteps
                });
                this.scrollToBottom();
            }, 4000);
        }, 750);
    }

    scrollToBottom() {
        let chat = document.getElementById('chat');
        chat.scrollTop = chat.scrollHeight;
    }

    handleChange(e) {
        let lastStep = (this.state.lastStep) ? this.state.lastStep : this.state.nextStep === this.state.totalSteps;

		this.setState({
			isTyping: false,
			totalSteps: document.getElementsByClassName('cm-chat-bot__step').length,
            prevStep: this.state.currentStep,
			currentStep: this.state.nextStep,
			nextStep: lastStep ? 0 : this.state.nextStep + 1,
			lastStep: lastStep,
		});

        if(lastStep) {
            setTimeout(() => {
                this.setState({
                    endChat: true
                });
                this.scrollToBottom();
            }, 1000);
        }

        this.scrollToBottom();
    }

    getRules(e) {
        let rules = e.target.getAttribute('data-rules');
        if(rules) {
            rules = rules.split(',').concat(this.state.rules);
            this.setState({
                rules
            });
        }
    }

    handleLoaderComplete(isComplete) {
		this.setState({
			isTyping: !isComplete,
		});
        this.scrollToBottom();
	}

    questionnaireStepComponent() {
        const renderQuestionnaireStepClassNames = (step, state) => {
            let modifierClass =
                step === state.currentStep
                    ? ' cm-chat-bot__step--active'
                    : step === state.nextStep
                    ? ' cm-chat-bot__step--next'
                    : step < state.currentStep
                    ? ' cm-chat-bot__step--shown'
                    : state.currentStep === 0 && state.lastStep
                    ? ' cm-chat-bot__step--shown'
                    : '';
            let hideTyping = (step === state.totalSteps) ? ' cm-chat-bot__step--no-typing' : '';
            let classList = 'cm-chat-bot__step' + modifierClass + hideTyping;
            return classList;
        };

        const renderStepTitle = (title, variableName, variableValue) => {
            if(variableName && variableValue) {
                let variable = this.variables[variableName];
                let value = this.state.response[variableValue];
                return replaceAllHelper(title, variable, value);
            } else {
                return title;
            }
        }

        const checkRules = (id) => {
            let skipStep = false;
            this.state.rules.forEach((rule) => {
                if(id === rule) {
                    skipStep = true;
                }
            });
            return skipStep;
        }

        let stepNum = 1;
        let steps = [];

        this.state.steps.map((step, index) => {
            if(!checkRules(step.id)) {
                steps.push(
                    <QuestionnaireStep
                        step={stepNum}
                        id={`step-${index + 1}`}
                        classNames={renderQuestionnaireStepClassNames(stepNum, this.state)}
                        key={index}
                        title={renderStepTitle(step.title, step.body[0].form.variable.name, step.body[0].form.variable.value)}
                        subTitle={step.subTitle}
                        body={step.body}
                        onChange={this.handleChange}
                        onClick={this.getRules}
                        chatBot
                        isChat={step.type === 'chat'}
                        currentStep={this.state.currentStep}
                    />
                );
                stepNum++;
            }
        });

        return steps;
    };

    render() {
        const createClassList = () => {
            let classList = 'cm-chat-bot';
            if(this.state.isTyping) classList = classList + ' cm-chat-bot--typing';
            if(this.state.showInbetweenSteps) classList = classList + ' cm-chat-bot--showing-inbetween-message';
            if(this.props.type) classList = classList + ' cm-chat-bot--' + this.props.type;
            if(this.props.classNames) classList = classList + ' ' + this.props.classNames;

            return classList;
        }

        const titleComponent = (this.state.title) ? (
                <Title
                    type='h3'
                    title={this.state.title}
                    align='center'
                    color='light'
                    classNames='cm-chat-bot__title'
                />
        ) : null;

        const subTitleComponent = (this.state.subTitle) ? (
            <Title
                type='h4'
                title={this.state.subTitle}
                align='center'
                color='light'
                classNames='cm-chat-bot__sub-title'
            />
        ) : null;

        const typingLoader =
			this.state.isTyping && !this.state.endChat ? (
				<div className='cm-chat-bot__typing-loader l-grid__row'>
					<Loader
						onComplete={this.handleLoaderComplete} 
						isTyping
					/>
				</div>
			) : null;

        const steps = this.state.showSteps ? (
            <div className='cm-chat-bot__steps'>
                {this.questionnaireStepComponent()}
            </div>
        ) : null;

        const chatStartedMessage = this.state.startChat ? (
            <p className='cm-chat-bot__message'>Start of chat</p>
        ) : null;


        const chatEndedMessage = this.state.endChat ? (
            <p className='cm-chat-bot__message'>End of chat</p>
        ) : null;

        const beginningMessage = (
            <div className='cm-chat-bot__start'>
                <p className='cm-chat-bot__message'>Chat will start shortly</p>
            </div>
        );
        
        return (
            <div className={createClassList()}>
                <div className='cm-chat-bot__wrapper'>
                    <div className='cm-chat-bot__heading'>
                        <div className='cm-chat-bot__heading-wrapper'>
                            {titleComponent}
                            {subTitleComponent}
                        </div>
                    </div>
                    <div className='cm-chat-bot__body'>
                        <div id="chat" className='cm-chat-bot__content'>
                            {beginningMessage}
                            {chatStartedMessage}
                            {typingLoader}
                            {steps}
                            {chatEndedMessage}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatBot;