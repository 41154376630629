import React, { Component } from 'react'

import Title from '../atoms/title'
import Link from '../atoms/link'
import BodyCopy from '../atoms/body-copy'
import Lists from '../atoms/lists'
import KeyFeatures from '../molecules/key-features'
import QuestionnaireTeaser from '../molecules/questionnaire-teaser'

class Search extends Component {
  constructor(props) {
      super(props);
      this.state = {
          content: this.props.content,
          UTMparams: ''
      }

      this.grabUTMparams = this.grabUTMparams.bind(this);
  }

  componentDidMount() {
    this.grabUTMparams();
  }

  grabUTMparams() {
    this.setState({
      UTMparams: window.location.search,
    });
  }

  render() {      
      return (
        <div className='co-search'>
          <div className='l-section'>
            <Title
                type='h1'
                title={this.state.content.section1.title1}
                classNames='co-search__title'
            />
            <Link 
              url={this.state.content.section1.image.linkUrl + this.state.UTMparams}
              align='center'
              classNames='co-search__image l-spacing__m-tb--24'
              type="image"
            >
              <img src={require(`../../images/${this.state.content.section1.image.src}`)} alt={this.state.content.section1.image.alt} />
            </Link>
          </div>

          <div className='l-section'>
            <div className='l-grid__row'>
              <div className='l-grid__col-xs-12 l-grid__col-lg'>
                <div className='l-container l-container--col'>
                  <Title
                    type='h2'
                    title={this.state.content.section2.title1}
                    classNames='co-search__title co-search__title--1 l-spacing__m-b--32'
                  />
                  <Lists
                    items={this.state.content.section2.list}
                    classNames='co-search__list-checkmark l-spacing__p-b--8'
                    checkmark
                  />
                  <QuestionnaireTeaser 
                    content={this.state.content.questionnaireTeaser}
                    classNames='co-search__questionnaire-teaser co-search__questionnaire-teaser--mobile u-desktop__hide l-spacing__m-tb--40'
                    UTMparams={this.state.UTMparams}
                  />
                  <Title
                    type='h3'
                    title={this.state.content.section2.title2}
                    align='left'
                    color='dark'
                    weight='bold'
                    classNames='co-search__title co-search__title--2 l-spacing__m-t--40'
                  />
                  <BodyCopy
                    copy={this.state.content.section2.bodyCopy}
                    classNames='co-search__copy'
                  />
                </div>
              </div>

              <div className='l-grid__col-xs-12 l-grid__col-lg'>
                <div className='l-container l-container--col'>
                  <QuestionnaireTeaser 
                    content={this.state.content.questionnaireTeaser}
                    classNames='co-search__questionnaire-teaser co-search__questionnaire-teaser--desktop u-desktop__show'
                    UTMparams={this.state.UTMparams}
                  />
                  <KeyFeatures
                    features={this.state.content.keyFeatures}
                    type='mini'
                    classNames='u-desktop__show'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='l-section l-section--t l-section--pop'>
            <div className='l-container l-container--pop'>
              <Title
                type='h3'
                title={this.state.content.section3.title1}
                align='left'
                color='dark'
                weight='bold'
                classNames='co-search__title'
              />

              <Lists
                items={this.state.content.section3.list}
                classNames='co-search__list'
              />

              <Link
                url={this.state.content.section3.image.linkUrl + this.state.UTMparams}
                align='center'
                classNames='co-search__image l-spacing__m-tb--40'
                type="image"
              >
                <img src={require(`../../images/${this.state.content.section3.image.src}`)} alt={this.state.content.section3.image.alt} />
              </Link>
            </div>
          </div>

          <div className='l-section'>
            <div className='l-container l-container--inner'>
              <KeyFeatures
                features={this.state.content.keyFeatures}
              />
            </div>
          </div>

        </div>
      );
  }
}

export default Search
