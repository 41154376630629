import React from 'react';

const TextInputField = ({
	className = false,
	classNameJS = false,
	isRequired = false,
	isAutoComplete = false,
	inputName = '',
	inputType = 'text',
	pattern = null,
	placeholder = null,
	errorMsg = null,
	buttonID = null,
	onChange = null,
	valueType = null,
	saveResponse = true,
	validateAPI = false
}) => {
	const createClassList = () => {
		let classList = 'ca-text-input-field';
		if (className) classList = classList + ' ca-text-input-field--' + className;
		if (classNameJS) classList = classList + ' ' + classNameJS;
		if (isRequired) classList = classList + ' js-required';
		return classList;
	};

	const autoCompleteValue = () => {
		switch (valueType) {
			case 'streetAddress':
				return 'address-line1';
			case 'streetAddress2':
				return 'address-line2';
			case 'city':
				return 'address-level2';
			case 'state':
				return 'address-level1';
			case 'zipCode':
				return 'postal-code';
			case 'phoneNumber':
				return 'tel';
			case 'email':
				return 'email';
			case 'fullName':
				return 'name';
			case 'firstName':
				return 'given-name';
			case 'lastName':
				return 'family-name';
			default:
				return 'on';
		}
	};

	return (
		<div className={createClassList()} data-button-id={buttonID}>
			<input
				type={inputType}
				name={inputName}
				pattern={pattern}
				placeholder={placeholder}
				required={isRequired}
				autoComplete={isAutoComplete ? autoCompleteValue() : 'off'}
				onChange={onChange}
				data-save={saveResponse}
				data-validate-api={validateAPI}
				data-value-type={valueType}
			/>
			<span className='ca-text-input-field__error-msg'>{errorMsg}</span>
		</div>
	);
};

export default TextInputField;

{
	/* <TextInputField
    classNameJS="js-show--button"
    isRequired="true || false"
    isAutoComplete="true || false"
    buttonID="button-id-goes-here"
    inputName="input-name-goes-here"
    placeholder="placeholder goes here"
    onChange={this.handleChange}
/> */
}
