import React from 'react';

const SelectField = ({
	className = false,
	classNameJS = false,
	isRequired = false,
	options = [],
	selectName = '',
	defaultValue = '',
	errorMsg = null,
	buttonID = null,
	onChange = null,
	valueType = null,
	saveResponse = true,
	validateAPI = false
}) => {
	const createClassList = () => {
		let classList = 'ca-select-field';
		if (className) classList = classList + ' ca-select-field--' + className;
		if (classNameJS) classList = classList + ' ' + classNameJS;
		if (isRequired) classList = classList + ' js-required';
		return classList;
	};

	let optionFields = options.map((option, index) => (
		<option
			value={option.value}
			disabled={option.disabled ? 'disabled' : null}
			// selected={(option.selected) ? 'selected' : null}
			key={index}
		>
			{option.name}
		</option>
	));

	return (
		<div className={createClassList()} data-button-id={buttonID}>
			<select
				name={selectName}
				onChange={onChange}
				defaultValue={defaultValue}
				data-value-type={valueType}
				data-save={saveResponse}
				data-validate-api={validateAPI}
			>
				{optionFields}
			</select>
			<span className='ca-select-field__error-msg'>{errorMsg}</span>
		</div>
	);
};

export default SelectField;

{
	/* <SelectField
    classNameJS="js-show--button"
    isRequired={item.form.required}
    options={item.options}
    buttonID={item.form.buttonID}
    selectName={item.form.name}
    defaultValue={item.defaultValue}
    onChange={this.handleChange}
/> */
}
