import React, { Component } from 'react'

import Title from '../atoms/title'
import Link from '../atoms/link'
import Button from '../atoms/button'
import BodyCopy from '../atoms/body-copy'
import Lists from '../atoms/lists'

class Advertorial extends Component {
  constructor(props) {
      super(props);
      this.state = {
          content: this.props.content,
          UTMparams: ''
      }

      this.grabUTMparams = this.grabUTMparams.bind(this);
  }

  componentDidMount() {
    this.grabUTMparams();
  }

  grabUTMparams() {
    this.setState({
      UTMparams: window.location.search,
    });
  }

  render() {      
      return (
        <div className='co-advertorial'>
          <div className='l-section'>
            <Title
                type='h1'
                title={this.state.content.section1.titleLevel1}
                classNames='co-advertorial__title'
            />
            <Link 
              url={this.state.content.section1.image.linkUrl + this.state.UTMparams}
              align='center'
              classNames='co-advertorial__image l-spacing__m-tb--40'
              type="image"
            >
              <img src={this.state.content.section1.image.src} alt={this.state.content.section1.image.alt} />
            </Link>
            <Title
                type='h5'
                title={this.state.content.section1.titleLevel3}
                align='left'
                color='dark'
                weight='bold'
                classNames='co-advertorial__title'
            />
            <BodyCopy
              copy={this.state.content.section1.bodyCopy}
              classNames='co-advertorial__copy'
            />
    
            <Button
              type='tertiary'
              url={this.state.content.section1.button.url + this.state.UTMparams}
              label={this.state.content.section1.button.label}
              id={this.state.content.section1.button.id}
              classNames='co-advertorial__button l-spacing__m-t--40 l-spacing__p-t--24'
              large
              align='center'
            />
          </div>
    
          <div className='l-section'>
            <Title
              type='h3'
              title={this.state.content.section2.titleLevel2}
              align='left'
              color='dark'
              weight='bold'
              classNames='co-advertorial__title'
            />
    
            <BodyCopy
              copy={this.state.content.section2.bodyCopy}
              classNames='co-advertorial__copy'
            />
    
            <Lists
              items={this.state.content.section2.list}
              classNames='co-advertorial__list'
            />
    
            <Title
                type='h5'
                title={this.state.content.section2.titleLevel3}
                align='left'
                color='dark'
                weight='bold'
                classNames='co-advertorial__title'
            />
          </div>
    
    
          <div className='l-section'>
            <Title
              type='h3'
              title={this.state.content.section3.titleLevel2}
              align='left'
              color='dark'
              weight='bold'
              classNames='co-advertorial__title'
            />
    
            <BodyCopy
              copy={this.state.content.section3.bodyCopy}
              classNames='co-advertorial__copy'
            />
    
            <Lists
              items={this.state.content.section3.list}
              classNames='co-advertorial__list'
            />
    
            <Link
              url={this.state.content.section3.image.linkUrl + this.state.UTMparams}
              align='center'
              classNames='co-advertorial__image l-spacing__m-tb--40'
              type="image"
            >
              <img src={this.state.content.section3.image.src} alt={this.state.content.section3.image.alt} />
            </Link>
    
            <BodyCopy
              copy={this.state.content.section3.bodyCopy2}
              classNames='co-advertorial__copy'
            />
    
            <Button
              type='tertiary'
              url={this.state.content.section3.button.url + this.state.UTMparams}
              label={this.state.content.section3.button.label}
              id={this.state.content.section3.button.id}
              classNames='co-advertorial__button l-spacing__m-t--40 l-spacing__p-t--24'
              large
              align='center'
            />
          </div>
    
        </div>
      );
  }
}

export default Advertorial
