import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Advertorial from '../components/organisms/advertorial'

const LandingPageAdvertorial = ({
  content
}) => {
  return (
    <Layout
      type='lp-ad'
      content={content.layout}
      classNames='t-landing-page t-landing-page--advertorial'
    >
      <SEO title={content.seo.title} />
      <Advertorial 
        content={content.landingPage}
      />
    </Layout>
  )
}

export default LandingPageAdvertorial
