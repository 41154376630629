import React from 'react'
import LandingPageSearch from '../../templates/landing-page-search'

import content from "../../data/test/templates/landing-page-search/content.json"

const TestTemplateLandingPageSearch = () => (
  <LandingPageSearch content={content} />
)

export default TestTemplateLandingPageSearch
