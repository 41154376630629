import React from 'react';

const Lists = ({
	items, 
    classNames = false,
    checkmark = false
}) => {
	const createClassList = () => {
        let classList = 'ca-lists';
        if (checkmark) classList = classList + ' ca-lists--checkmark';
        if (classNames) classList = classList + ' ' + classNames;
        return classList;
    }

	const listItems = () => {
        if(items.constructor !== Array) return;

        let paragraphs = [];
        for (let i = 0; i < items.length; i++) {
            paragraphs.push(<li key={i} dangerouslySetInnerHTML={{ __html: items[i] }} />);
        }
        return paragraphs;
	}
	
	return (
		<div className={createClassList()}>
            <ul>
			    {listItems()}
            </ul>
		</div>
	);
};

export default Lists;
