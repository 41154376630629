import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
import ChatBot from '../components/molecules/chat-bot'

import content from "../data/pages/thank-you-chat/content.json"

const ThankYouPage = () => (
  <Layout hideSecureForm>
    <SEO title='Thank You' />
    <Heading 
        title={content.message.title}
        subTitle={content.message.subTitle}
        weight='light'
    />
    <ChatBot 
        content={content.chatBot}
        classNames='l-spacing__m-tb--40'
    />
  </Layout>
)

export default ThankYouPage
