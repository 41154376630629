import React from 'react'
import LandingPageAdvertorial from '../../templates/landing-page-advertorial'

import content from '../../data/pages/article/opf-funding-alternative/content.json'

const OPFFundingAlternativeLP = () => (
  <LandingPageAdvertorial content={content} />
)

export default OPFFundingAlternativeLP
