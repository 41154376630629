import React from 'react';

const Button = ({
	type,
	url = false,
	align = false,
	label,
	shadow = false,
	large = false,
	medium = false,
	isForm = false,
	classNames = false,
	linkClassNames = false,
	id = null,
	onClick = false
}) => {
	const createClassList = () => {
		let classList = 'ca-button ca-button--' + type;
		if (shadow) classList = classList + ' ca-button--shadow';
		if (align) classList = classList + ' ca-button--' + align;
		if (large) classList = classList + ' ca-button--large';
		if (medium) classList = classList + ' ca-button--medium';
		if (isForm) classList = classList + ' ca-button--form';
		if (classNames) classList = classList + ' ' + classNames;
		return classList;
	};

	const onClickValue = (onClick) ? onClick : () => { console.log('inside'); window.location.href=`${url}`};

	return (
		<div className={createClassList()}>
			<button
				type='button'
				id={id}
				onClick={onClickValue}
				className={(linkClassNames) ? linkClassNames : null}
			>
				{label}
			</button>
		</div>
	);
};

export default Button;
