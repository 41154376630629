import React from 'react'
import SEO from "../../../../components/atoms/seo"

import Layout from '../../../../components/organisms/layout'
import Heading from '../../../../components/molecules/heading'
import Questionnaire from '../../../../components/organisms/questionnaire'
import KeyFeatures from '../../../../components/molecules/key-features'

import steps from "../../../../data/pages/product/es/form/steps.json"
import content from "../../../../data/pages/es/questionnaire/content.json"

const QuestionnairePageES = () => (
  <Layout content={content.layout}>
    <SEO title={content.seo.title} />
    <Heading 
        title={content.heading.title}
        subTitle={content.heading.subTitle}
        weight='light'
        />
    <Questionnaire 
      steps={steps}
      content={content.questionnaire}
      spanish
    />
    <KeyFeatures
      features={content.keyFeatures}
    />
  </Layout>
)

export default QuestionnairePageES
