import React, { Component } from 'react';
import $ from 'jquery';
import {
	formatCurrency,
	formatPhoneText,
	formatDate,
	formatZipCode,
	isValidDate,
	isMobile,
	scrollToTop,
	regExValidation,
	grabUTMparams,
	grabResponseParam,
	isObjEmpty,
	replaceAllHelper,
} from '../../helpers';

import Button from '../atoms/button';
import Heading from '../molecules/heading';
import QuestionnaireStep from '../molecules/questionnaire-step';
import Loader from '../molecules/loader';

class Questionnaire extends Component {
	constructor(props) {
		super(props);
		this.state = {
			thankYouURL: this.props.thankYouURL,
			disclaimers: this.props.content.disclaimers,
			nextButtonLabel: this.props.content.buttons.next,
			submitButtonLabel: this.props.content.buttons.submit,
			messages: this.props.content.messages,
			onSubmitRedirect: this.props.content.onSubmit?.redirect ? this.props.content.onSubmit.redirect : false,
			steps: this.props.steps,
			formName: this.props.formName,
			totalSteps: this.props.steps.length,
			isSpanish: this.props.spanish,
			isThankYouChatBot: this.props.thankYouChatBot,
			showTextLoader: this.props.showTextLoader,
			currentStep: 1,
			currentStepName: '',
			currentStepButtonID: '',
			nextStep: 2,
			lastStep: false,
			showNextBtn: this.props.steps[0].showButton,
			showDisclaimer: false,
			showSubmit: false,
			showThankYouPage: false,
			delay: false,
			isLoading: false,
			loaderProgress: 10,
			showLinearLoader: false,
			showCircularLoader: false,
			sliderValue: 0,
			sliderIcon: this.props.sliderIcon,
			qualify: false,
			validateAPI: false,
			redirectURL: false,
			rules: [],
			response: {},
			submitData: {},
			chatBotData: {},
			postSubmitURL: '/api/submit',
			getEmailValidURL: '/api/getEmailValid',
			getPhoneValidURL: '/api/getPhoneValid'
		};

        this.readUrlParams = this.readUrlParams.bind(this);

		this.setStateForNextStep = this.setStateForNextStep.bind(this);
		// this.createStepDisclaimer = this.createStepDisclaimer.bind(this);
		this.handleStepChange = this.handleStepChange.bind(this);
		this.handleLoaderComplete = this.handleLoaderComplete.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleGetValueValidAPI = this.handleGetValueValidAPI.bind(this);

		this.createDataForSubmit = this.createDataForSubmit.bind(this);
		this.getEmailValueValidAPI = this.getEmailValueValidAPI.bind(this);
		this.getPhoneValueValidAPI = this.getPhoneValueValidAPI.bind(this);
		this.postSubmitAPI = this.postSubmitAPI.bind(this);
	}

	componentDidMount() {
        this.readUrlParams();
    }

	readUrlParams() {
		let utmParamsObj = grabUTMparams();
		let responseParamObj = grabResponseParam('resp');

		if(!isObjEmpty(utmParamsObj)) {
			Object.keys(utmParamsObj).forEach((key, index) => {			
				let value = utmParamsObj[key];
				this.saveSubmitData(key, value);
			})
		}

		if(!isObjEmpty(responseParamObj)) {
			Object.keys(responseParamObj).forEach((key, index) => {			
				let value = responseParamObj[key];
				let response = {
					key: key,
					val: value,
				};
				this.setStateForNextStep(response);
			})
		}
    }

	postSubmitAPI() {
		return new Promise((resolve, reject) => {
			// let data = {
			// 	"company_is_open":1,
			// 	"company_revenue":10000,
			// 	"company_start":"12/31/2019",
			// 	"company_has_checking":1,
			// 	"company_bankcruptcy":1,
			// 	"company_need":5000,
			// 	"company_name":"fghfdghdfh",
			// 	"email":"priscillapi00122@mailinator.com",
			// 	"phone":"+19543052242",
			// 	"name":"Priscilla",
			// 	"lname":"Pi"
			//  }
			$.ajax({
				type: 'POST',
				url: this.state.postSubmitURL,
				data: JSON.stringify(this.state.submitData),
				// data: data,
				crossDomain: true,
				success: (response, status, xhr) => {
					console.log('success');
					setTimeout(() => resolve(JSON.parse(response)), 2000);
				},
				error: (xhr, ajaxOptions, thrownError) => {
					console.log('error');
					setTimeout(() => resolve({valid:false}), 2000);
				},
			});
		});
	}

	getEmailValueValidAPI() {
		return new Promise((resolve, reject) => {
			$.ajax({
				type: 'GET',
				url: this.state.getEmailValidURL,
				data: 'email=' + this.state.response.email,
				crossDomain: true,
				success: (response, status, xhr) => {
					console.log('success');
					let responseParsed = JSON.parse(response);
					// console.log('responseParsed: ', responseParsed);
					resolve(responseParsed.valid);
				},
				error: (xhr, ajaxOptions, thrownError) => {
					console.log('error');
					resolve(false);
				},
			});
		});
	}

	getPhoneValueValidAPI() {
		return new Promise((resolve, reject) => {
			$.ajax({
				type: 'GET',
				url: this.state.getPhoneValidURL,
				data: 'phone=' + this.state.response.phone,
				crossDomain: true,
				success: (response, status, xhr) => {
					console.log('success');
					let responseParsed = JSON.parse(response);
					if(responseParsed.valid) {
						this.saveQuestionnaireResponse('phone', replaceAllHelper(this.state.response.phone.trim(), '-', ''));
					}
					resolve(responseParsed.valid);
				},
				error: (xhr, ajaxOptions, thrownError) => {
					console.log('error');
					resolve(false);
				},
			});
		});
	}

	createDataForSubmit(resolve, reject) {
		let monthValue = '', yearValue = '';
		let createDate = false;
		Object.keys(this.state.response).forEach((key, index) => {
			let value = this.state.response[key];
			let newValue = value;
			let newKey = key;
			let saveData = true;


			if (key === 'company_is_open' || key === 'company_has_checking' || key === 'company_bankcruptcy' || key === 'company_need' || key === 'company_revenue') {
				newValue = parseInt(value);
			}

			if (key === 'company_start-month') {
				saveData = false;
				monthValue = value;
				createDate = (monthValue !== '' && yearValue !== '');
			}

			if (key === 'company_start-year') {
				saveData = false;
				yearValue = value;
				createDate = (monthValue !== '' && yearValue !== '');
			}

			if(createDate && (key === 'company_start-month' || key === 'company_start-year')) {
				saveData = true;
				newKey = 'company_start';
				newValue = monthValue + '/01/' + yearValue;
			}

			if(key === 'name' || key === 'lname' || key === 'phone' || key === 'email' || key === 'company_name') {
				this.setState((prevState) => ({
					chatBotData: {
						...prevState.chatBotData,
						[key]: newValue,
					},
				}));
			}

			if(saveData) {
				this.setState((prevState) => ({
					submitData: {
						...prevState.submitData,
						[newKey]: newValue,
					},
				}));
			}
		});


		if(this.state.formName && this.state.formName === 'tcw15-22') {
			console.log('1');
			this.setState((prevState) => ({
				submitData: {
					...prevState.submitData,
					"company_is_open": 1,
					"opf-uvid": "CP-215"
				},
			}));
		}

		resolve();
	}

	handleSubmit() {
		const checkRedirectRules = () => {
			switch (this.state.onSubmitRedirect.rule.condition) {
				case 'less than':
					return (this.state.submitData[this.state.onSubmitRedirect.rule.key] < this.state.onSubmitRedirect.rule.value);
				default:
					return true;
			}
		}

		this.setState({
			isLoading: !this.state.isLoading,
			showCircularLoader: !this.state.showCircularLoader,
		});
		const submitDataPromise = new Promise(this.createDataForSubmit);
		submitDataPromise.then(this.postSubmitAPI).then((result) => {
			let spanishDirectory = (this.state.isSpanish) ? '/es' : '';
			if(this.state.onSubmitRedirect) {
				console.log('2');
				if(this.state.formName) {
					console.log('3');
					if(this.state.formName !== 'tcw15-22') {
						console.log('4');
						let useRedirectURL = checkRedirectRules();
						if(useRedirectURL) {
							console.log('5');
							return window.location.href = this.state.onSubmitRedirect.url;
						}
					}
				} else {
					console.log('6');
					let useRedirectURL = checkRedirectRules();
					if(useRedirectURL) {
						console.log('7');
						return window.location.href = this.state.onSubmitRedirect.url;
					}
				}
			}
			let chatResponse = '';
			let length = Object.keys(this.state.chatBotData).length;
			let pos = 0;

			$.each(this.state.chatBotData, (key, value) => {
				pos++;
				let connectingCharacter = (pos === length) ? '' : '&';
				chatResponse = chatResponse + key + '=' + value + connectingCharacter;
			});

			let chatResponseEncoded = window.btoa(chatResponse);
			let chat = '&chat=' + chatResponseEncoded;
			let thankYouURL = (this.state.thankYouURL) ? this.state.thankYouURL : 'thank-you';
			let leadID = false;

			
			if(result.valid) {
				console.log('inside valid 1');
				if(result.data.url) {
					console.log('inside valid 2');
					if(this.state.formName) {
						console.log('inside valid 3');
						if(this.state.formName !== 'tcw15-22') {
							console.log('inside valid 4');
							return window.location.href = result.data.url;
						}
					} else {
						console.log('inside valid 5');
						return window.location.href = result.data.url;
					}
				} else {
					console.log('inside valid 6');
					leadID = result.data.id;
				}
			}
			let urlParams = '/' + thankYouURL + '/?leadID=' + leadID + chat;
			return window.location.href = window.location.origin + spanishDirectory + urlParams;
		});
	}

	handleGetValueValidAPI(key, value) {
		return new Promise((resolve, reject) => {
			const getValueValidAPIPromise = new Promise((resolve, reject) => {
				this.saveQuestionnaireResponse(key, value);
				resolve();
			});
			getValueValidAPIPromise.then((key === 'email') ? this.getEmailValueValidAPI : this.getPhoneValueValidAPI).then((res) => {
				resolve(res);
			});
		});
	}

	async validateFormItem() {
		const toggleErrorClassName = (isValid, formItem) => {
			if (isValid) {
				formItem.classList.remove('v-error');
			} else {
				formItem.classList.add('v-error');
			}
		}
		let formStep = document.getElementsByClassName('co-questionnaire__step--active');
		let formItems = formStep && formStep.length ? formStep[0].getElementsByClassName('js-required') : false;
		let isRequired = formItems && formItems.length > 0;

		if (!isRequired) return true;

		let isValid = true;
		let isSelect, isEmail, isPhone, isDate, isCompanyRevenue = false;
		let formField, formFieldName, value, valueMonth, valueYear, valueType, formItem, valueCurrency = '';

		$.each(formItems, (index, item) => {
			formItem = item;
			formField = formItem.firstChild;
			formFieldName = formField.name;
			valueType = formField.dataset.valueType;
			isSelect = formItem.classList.contains('ca-select-field');
			isEmail = valueType === 'email';
			isPhone = valueType === 'phoneNumber';
			isCompanyRevenue = valueType === 'companyRevenue';


			// console.log('isSelect: ', isSelect);
			// console.log('formFieldName: ', formFieldName);


			// isDate = valueType === 'date';
			// console.log('item: ', item);
			// console.log('valueType: ', valueType);

			// if(isDate) {
			// 	if(formFieldName.includes('month')) {
			// 		valueMonth = value;
			// 	} else if(formFieldName.includes('year')) {
			// 		valueYear = value;
			// 	}
			// } else {

			// }

			if(isSelect) {
				isValid = Object.keys(this.state.response).find(key => key === formFieldName);
			} else {
				value = (formField.value = formField.value.trim());
				isValid = regExValidation(valueType, value);
			}

			toggleErrorClassName(isValid, formItem);
		});

		if(isValid && (isEmail || isPhone)) {
			isValid = await this.handleGetValueValidAPI(formFieldName, value);
			// isValid = true;
			toggleErrorClassName(isValid, formItem);
		} 

		if(isValid && isCompanyRevenue) {
			if(value.length > 0) {
				valueCurrency = parseInt(replaceAllHelper(value.trim(), ',', ''));
				if (valueCurrency < 7500) {
					console.log('8');
					if (this.state.formName) {
						console.log('9');
						if (this.state.formName !== 'tcw15-22') {
							console.log('10');
							setTimeout(() => {window.location.href = "https://app.oneparkfinancial.com/display-results"}, 1000);
						}
					} else {
						console.log('11');
						setTimeout(() => {window.location.href = "https://app.oneparkfinancial.com/display-results"}, 1000);
					}
				}
				if(valueCurrency < 7500) {
					console.log('12');
					if(this.state.formName) {
						console.log('13');
						if(this.state.formName !== 'tcw15-22') {
							console.log('14');
							setTimeout(() => {window.location.href = "https://app.oneparkfinancial.com/display-results"}, 1000);
						}
					} else {
						console.log('15');
						setTimeout(() => {window.location.href = "https://app.oneparkfinancial.com/display-results"}, 1000);
					}

				}
				this.saveQuestionnaireResponse('company_revenue', valueCurrency);
			} else {
				isValid = false;
				toggleErrorClassName(isValid, formItem);
			}
		}

		
		// figure out issue with date 
		// year is disappearing on selection
		// else if(isValid && isDate) {
		// 	console.log('inside if');
		// 	console.log('valueMonth: ', valueMonth);
		// 	console.log('valueYear: ', valueYear);

		// }

		return isValid;
	}

	async onButtonClick(e, submitClicked) {
		const sliderResponse = (activeStepSlider) => {
			let sliderInputField = $('.co-questionnaire__step--active .ca-slider-field input');
			let sliderNameValue = {
				key: activeStepSlider.data('slider'),
				val: sliderInputField[0].value,
			};
			return sliderNameValue;
		};

		const disableButton = (button, value = true) => {
			if(this.state.validateAPI) button.disabled = value;
		};

		let target = e.target;

		disableButton(target);

		let valid = await this.validateFormItem();

		if (!valid) {
			disableButton(target, false);
			return console.log('error-- value not valid');
		}

		if (isMobile()) scrollToTop();

		let lastStep = target.classList.contains('js-submit__last-step');

		if (submitClicked && lastStep) {
			this.handleSubmit();
		} else {
			let activeStepSlider = $('.co-questionnaire__step--active .ca-slider-field');
			let response = activeStepSlider.length ? sliderResponse(activeStepSlider) : false;
			this.setStateForNextStep(response);
		}

		disableButton(target, false);
	}

	handleStepChange(e, sliderNewValue = false) {
		const handleChange = (e) => {
			// console.log('inside handleChange()');
			
			let saveResponse = e.target.dataset.save === 'true';
			let validateAPI = e.target.dataset.validateApi === 'true';

			let formatTextValue = (value, valueType) => {
				// console.log('inside formatTextValue()');
				// console.log('value: ', value);
				// console.log('valueType: ', valueType);

				switch (valueType) {
					case 'companyRevenue':
						return formatCurrency(value);
					case 'phoneNumber':
						return formatPhoneText(value);
					case 'date':
						return formatDate(value);
					case 'zipCode':
						return formatZipCode(value);
					default:
						return value;
				}
			};
			let key = e.target.name;
			let val = (e.target.value = formatTextValue(e.target.value, e.target.dataset.valueType));

			// console.log('key: ', key);
			// console.log('val: ', val);

			if (saveResponse) {
				this.saveQuestionnaireResponse(key, val);
				if(validateAPI && !this.state.validateAPI) {
					this.setState({
						validateAPI: !this.state.validateAPI,
					});
				}
			}
		};

		const handleOptionChange = (e) => {
			let saveResponse = e.target.dataset.save === 'true';
			let urlExists = e.target.dataset.url ? e.target.dataset.url : false;
			let optionNameValue = {
				key: e.target.name,
				val: e.target.value,
			};

			if (this.state.lastStep && saveResponse) {
				this.saveQuestionnaireResponse(optionNameValue.key, optionNameValue.val);
			} else if (!this.state.lastStep) {
				this.setStateForNextStep(saveResponse ? optionNameValue : false, urlExists);
			}
		};

		const handleSliderChange = (newValue) => {
			this.setState({
				sliderValue: newValue,
			});
		};

		// console.log('e.target.type: ', e.target.type);

		if (e.target.type === 'text' || e.target.type === 'email' || e.target.type === 'select-one') {
			// console.log('inside if');
			handleChange(e);
		} else if (e.target.type === 'radio') {
			handleOptionChange(e);
		} else if (sliderNewValue !== false) {
			handleSliderChange(sliderNewValue);
		}
	}

	handleLoaderComplete(isComplete) {
		if (this.state.redirectURL) {
			console.log('4');
			return window.location.href = this.state.redirectURL;
		}

		this.setState({
			isLoading: !isComplete,
			showLinearLoader: !isComplete,
		});
	}

	setStateForNextStep(response = false, redirectURL = false) {
		const showSubmitBtn = (lastStep) => {
			if (lastStep) return true;

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');
			return formItemNextStep[0].classList.contains('js-show__submit');
		};

		const showNextBtn = () => {
			const isNextButtonNeeded = (step) => {
				return step.getElementsByClassName('js-show--button').length;
			};

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');

			let showNextBtn = formItemNextStep && formItemNextStep.length ? isNextButtonNeeded(formItemNextStep[0]) : false;

			return showNextBtn;
		};

		const showDisclaimer = () => {
			const isDisclaimerNeeded = (step) => {
				return step.getElementsByClassName('js-show--disclaimer').length;
			};

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');

			let showDisclaimer =
				formItemNextStep && formItemNextStep.length ? isDisclaimerNeeded(formItemNextStep[0]) : false;

			return showDisclaimer;
		};

		const currentStepName = () => {
			if (!showNextBtn()) return '';

			let textField = $('[data-step="' + this.state.nextStep + '"] .ca-text-input-field input');
			let sliderField = $('[data-step="' + this.state.nextStep + '"] .ca-slider-field input');

			if (textField.length) {
				return textField.attr('name');
			} else if (sliderField.length) {
				return sliderField.attr('name');
			}
		};

		const currentStepButtonID = (lastStep) => {
			if (!showNextBtn() && !showSubmitBtn(lastStep)) return '';

			let textField = $('[data-step="' + this.state.nextStep + '"] .ca-text-input-field');
			let sliderField = $('[data-step="' + this.state.nextStep + '"] .ca-slider-field');
			let selectField = $('[data-step="' + this.state.nextStep + '"] .ca-select-field');

			if (textField.length) {
				return textField.data('button-id');
			} else if (sliderField.length) {
				return sliderField.data('button-id');
			} else if(selectField.length) {
				return selectField.data('button-id');
			}
		};

		let lastStep = this.state.nextStep === this.state.totalSteps;

		this.setState({
			isLoading: !this.state.isLoading,
			showLinearLoader: !this.state.showLinearLoader,
			totalSteps: document.getElementsByClassName('co-questionnaire__step').length,
			currentStep: this.state.nextStep,
			currentStepName: currentStepName(),
			currentStepButtonID: currentStepButtonID(lastStep),
			nextStep: lastStep ? 0 : this.state.nextStep + 1,
			lastStep: lastStep,
			sliderValue: 0,
			showSubmit: showSubmitBtn(lastStep),
			showNextBtn: showNextBtn(),
			showDisclaimer: showDisclaimer(),
			validateAPI: false,
			redirectURL: redirectURL
		});

		if (response !== false) {
			this.saveQuestionnaireResponse(response.key, response.val);
		}
	}

	saveQuestionnaireResponse = (key, val) => {
		this.setState((prevState) => ({
			response: {
				...prevState.response,
				[key]: val,
			},
		}));
	};

	saveSubmitData = (key, val) => {
		this.setState((prevState) => ({
			submitData: {
				...prevState.submitData,
				[key]: val,
			},
		}));
	};

	renderQuestionnaireSteps = () => {
		const checkRules = (title, rules) => {
			let skipStep = false;
			rules.forEach((rule) => {
				if (title === rule) {
					skipStep = true;
				}
			});
			return skipStep;
		};

		const getRules = (e) => {
			const setStateRules = (rules) => {
				this.setState({
					rules,
				});
			};

			let rules = e.target.getAttribute('data-rules');

			if (rules) {
				rules = rules.split(',').concat(this.state.rules);
				setStateRules(rules);
			}
		};

		const createStepDisclaimer = () => {
			console.log('inside createStepDisclaimer');
		};

		const renderQuestionnaireStepClassNames = (step, showSubmit, state) => {
			let modifierClass =
				step === state.currentStep
					? ' co-questionnaire__step--active'
					: step === state.nextStep
					? ' co-questionnaire__step--next'
					: '';
			let showSubmitClass = showSubmit ? ' js-show__submit' : '';
			let classList = 'co-questionnaire__step' + modifierClass + showSubmitClass;
			return classList;
		};

		let stepNum = 1;
		let steps = [];
		let showSubmit = false;

		this.state.steps.map((step, index) => {
			showSubmit = step.showSubmit ? true : false;
			if (!checkRules(step.title, this.state.rules)) {
				steps.push(
					<QuestionnaireStep
						step={stepNum}
						id={`step-${index + 1}`}
						classNames={renderQuestionnaireStepClassNames(stepNum, showSubmit, this.state)}
						key={index}
						title={step.title}
						subTitle={step.subTitle}
						body={step.body}
						sliderValue={this.state.sliderValue}
						disclaimer={step.disclaimer}
						rules={getRules}
						onChange={this.handleStepChange}
						sliderIcon={this.state.sliderIcon}
					/>
				);
				stepNum++;
			}
		});

		return steps;
	};

	render() {
		const questionnaireSteps = this.renderQuestionnaireSteps();

		const nextButton =
			this.state.showNextBtn &&
			!this.state.showSubmit &&
			!this.state.isLoading &&
			!this.state.qualify ? (
				<div className='l-grid__row l-grid__center-xs co-questionnaire__button'>
					<Button
						type='primary'
						label={this.state.nextButtonLabel}
						id={this.state.currentStepButtonID}
						onClick={(e) => {
							this.onButtonClick(e);
						}}
						large
						isForm
					/>
				</div>
			) : null;

		const submitButton = this.state.showSubmit ? (
			<Button
				type='primary'
				label={this.state.submitButtonLabel}
				id={this.state.currentStepButtonID}
				classNames='co-questionnaire__button'
				linkClassNames={this.state.lastStep ? 'js-submit__last-step' : null}
				onClick={(e) => {
					this.onButtonClick(e, true);
				}}
				large
				isForm
			/>
		) : null;

		const disclaimerAboveButton =
			this.state.showSubmit &&
			!this.state.isLoading &&
			this.state.disclaimers.aboveSubmitButton ? (
				<div className='co-questionnaire__disclaimer co-questionnaire__disclaimer--above-btn'>
					<p>{this.state.disclaimers.aboveSubmitButton}</p>
				</div>
			) : null;

		const disclaimer = this.state.showSubmit && !this.state.isLoading ? (
			<div className='co-questionnaire__disclaimer'>
				<p dangerouslySetInnerHTML={{ __html: this.state.disclaimers.belowSubmitButton }} />
			</div>
		) : null;

		const disclaimerTelephone = this.state.currentStepName === 'phone' && !this.state.isLoading ? (
			<div className='co-questionnaire__disclaimer'>
				<p>{this.state.disclaimers.phone}</p>
			</div>
		) : null;

		const form =
			!this.state.isLoading && !this.state.qualify ? (
				<form id='questionnaire-form' className='l-grid__row l-grid__center-xs'>
					{questionnaireSteps}
					{disclaimerAboveButton}
					{nextButton}
					{submitButton}
					{disclaimer}
				</form>
			) : null;

		const linearLoader =
			this.state.isLoading && this.state.showLinearLoader && !(this.state.showTextLoader && this.state.lastStep) ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader title={this.state.messages.savingAnswer} onComplete={this.handleLoaderComplete} isLinear />
				</div>
			) : null;
		
		const linearLoaderBeforeLastQuestion =
			this.state.isLoading && this.state.showLinearLoader && this.state.showTextLoader && this.state.lastStep ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader title={this.state.messages.savingAnswer} onComplete={this.handleLoaderComplete} isLinear showTextLoader text="Over $35 million funded to transportation companies in the last 12 months!" />
				</div>
			) : null;

		const circularLoader =
			this.state.isLoading && this.state.showCircularLoader ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader title={this.state.messages.processingSubmit} isCircular />
				</div>
			) : null;

		return (
			<div className='co-questionnaire l-spacing__p-b--40'>
				<div className='co-questionnaire__wrapper'>
					{form}
					{linearLoader}
					{linearLoaderBeforeLastQuestion}
					{circularLoader}
					{disclaimerTelephone}
				</div>
			</div>
		);
	}
}

export default Questionnaire;
