import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';

const Link = ({
	children,
	url = false,
	target = '_self',
	classNames = false,
	id = null,
	external = false,
	promo = false,
	type = 'link',
	onClick = false,
	align = false,
	activeClassName = null,
}) => {
	const createClassList = () => {
		let classList = 'ca-link' + ' ca-link--' + type;
		if (align) classList = classList + ' ca-link--' + align;
		return classList;
	};

	const createURL = external ? (
		<a id={id} href={url} target={target} className={classNames ? classNames : null}>
			{children}
		</a>
	) : onClick ? (
		<a id={id} target={target} className={classNames ? classNames : null} onClick={onClick}>
			{children}
		</a>
	) : promo ? (
		<span className={classNames ? classNames : null}>{children}</span>
	) : (
		<GatsbyLink
			id={id}
			to={url}
			target={target}
			className={classNames ? classNames : null}
			activeClassName={activeClassName}
		>
			{children}
		</GatsbyLink>
	);

	return <div className={createClassList()}>{createURL}</div>;

	// <Link
	//     url="/"
	//     classNames="co-header--link"
	//     target="_self || _blank"
	//     external
	// >children</Link>
};

Link.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Link;
