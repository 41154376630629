import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/main.scss';
import data from '../../data/layout/content.json'

import Header from './header';
import Footer from './footer';

const Layout = ({ 
	children,
	type,
	content,
	classNames = false
}) => {
	const createClassList = () => {
		let classList = 'l-wrapper';
		if (classNames) classList = classList + ' ' + classNames;
		return classList;
	};
	return (
		<div className={createClassList()}>
			<Header
				type={type}
				content={(content && content.header) ? content.header : data.header}
			/>
			<main className='l-container'>{children}</main>
			<Footer
				links={(content && content.footer && content.footer.links) ? content.footer.links : data.footer.links}
				copyright={(content && content.footer && content.footer.copyright) ? content.footer.copyright : data.footer.copyright}
				disclaimer={(content && content.footer && content.footer.disclaimer) ? content.footer.disclaimer : data.footer.disclaimer}
				pageDisclaimer={(content && content.footer && content.footer.pageDisclaimer) ? content.footer.pageDisclaimer : false}
			/>
		</div>
	)
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
